@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@500&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  font-family: 'Space Grotesk', sans-serif;
  max-width: 1440px;
}



.front-card {
  width: 250px;
  height: 150px;
  background-image: url("images/bg-card-front.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  position: absolute;
  top: 130px;
  left: 30px;
  z-index: 1;
}

.back-card {
  width: 250px;
  height: 150px;
  background-image: url("images/bg-card-back.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  position: absolute;
  top: 50px;
  left: 80px;
}

/* .form {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  justify-content: center;
  margin: 100px 30px 
} */

@media (min-width: 450px) {
  .front-card {
    width: 400px;
    height: 250px;
    background-image: url("images/bg-card-front.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    position: absolute;
    top: 200px;
    left: 200px;
  }
  
  .back-card {
    width: 400px;
    height: 250px;
    background-image: url("images/bg-card-back.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    position: absolute;
    top: 500px;
    left: 300px;
  }
}


